/* Typography */
.h1 {
  @apply text-6xl font-extrabold;
}

.h2 {
  @apply text-5xl font-extrabold;
}

.h3 {
  @apply text-4xl font-extrabold;
}

.h4 {
  @apply text-3xl font-extrabold;
}

@screen md {
  .h1 {
    @apply text-8xl;
  }

  .h2 {
    @apply text-7xl;
  }

  .h3 {
    @apply text-5xl;
  }
}

/* Buttons */
.btn,
.btn-sm {
  @apply text-sm font-medium inline-flex items-center justify-center border border-transparent rounded-full transition duration-150 ease-in-out;
}

.btn {
  @apply px-4 py-1.5;
}

.btn-sm {
  @apply px-3 py-1;
}

/* Forms */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
  @apply bg-slate-800 border border-transparent focus:border-lavareachOG;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
  @apply rounded;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select {
  @apply text-slate-200 text-sm px-3 py-1.5;
}

.form-input,
.form-textarea {
  @apply placeholder-slate-500;
}

.form-select {
  @apply pr-10;
}

.form-checkbox,
.form-radio {
  @apply text-lavareachOG rounded-sm;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
